import anime from 'animejs/lib/anime.es.js';

export default () => {
    let timeline = anime.timeline({ loop: true });
    let buzzwords = document.querySelectorAll('.buzzword');
    buzzwords.forEach((b)=>{
        timeline.add({
            targets: b.children,
            easing: 'easeInOutQuad',
            opacity: '1',
            duration: 10,
            delay: anime.stagger(80),
        }).add({
            targets: b.children,
            opacity: '1',
            duration: 2000,
            translateX: [-20, 0],
            translateY: 'initial',
            delay: anime.stagger(0),
        }).add({
            targets: b.children,
            easing: 'easeInOutQuad',
            color: 'red',
            opacity: '0',
            duration: 300,
            delay: anime.stagger(0),
        });
    });
};
