import $ from 'jquery';
// import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';


export default () => {
    if($(".events-splide").length){
        $(".events-splide").each(function(index) {
            let id = $(this).attr('id');
            new Splide(`#${id}`, {
                type: 'loop',
                pagination: false
            }).mount();
        })
    }
}
