import AnimatedBuzzword from './AnimatedBuzzword';
import ArrowButton from './ArrowButton';
import EventsSplide from './EventsSplide';
import Ticker from './Ticker';
import TitleAnimation from './TitleAnimation';
import WavyButtonAnime from './WavyButtonAnime';


AnimatedBuzzword();
ArrowButton();
EventsSplide();
Ticker();
TitleAnimation();
WavyButtonAnime();
