import anime from 'animejs/lib/anime.es.js';

export default () => {
    anime({
        targets: '.home__heading h1',
        translateX: [-100, 0],
        opacity: [0, 1],
        duration: 2040,
        delay: anime.stagger(200),
        easing: 'spring(1, 50, 10, 0)'
    });
};
