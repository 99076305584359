import anime from 'animejs/lib/anime.es.js';

const spin = function (item) {
    anime({
        targets: item,
        rotate: [0, 360],
        duration: 1000,
    });
};

const spiralShink = function (item) {
    anime.timeline({
        targets: item,
    }).add({
        rotate: 1080,
        scale: 0.6,
        duration: 3200,
    }).add({
        rotate: 0,
        scale: 1,
        duration: 420,
    });
};

const coinSpin = function (item) {
    anime.timeline({
        targets: item,
        duration: 2400,
        easing: 'easeOutBack',
    }).add({
        rotateY: [0, 720],
    });
};

const squatAndSquish = function (item) {
    anime.timeline({
        targets: item,
        duration: 800,
        easing: 'easeOutQuint',
    }).add({
        scaleX: 0.8,
        scaleY: 0.4,
    }).add({
        scaleX: 1,
        scaleY: 1,
        duration: 420,
        easing: 'easeOutBounce',
    });
};

/* Assign random animation to all svgs */

const animationLibrary = {
    'spin': (e) => spin(e),
    'spiralShink': (e) => spiralShink(e),
    'coinSpin': (e) => coinSpin(e),
    'squatAndSquish': (e) => squatAndSquish(e),
};

const allSvgs = document.querySelectorAll('.wavy-cta__button-container');

export default () => {
    for (const element of allSvgs) {
        let randomIndex = Math.floor(Math.random() * Object.keys(animationLibrary).length);
        let animationName = Object.keys(animationLibrary)[randomIndex];
        let graphic = element.children[1];

        element.addEventListener('mouseover', () => {
            animationLibrary[animationName](element.children[1]);
        });

        graphic.addEventListener('click', () => {
            animationLibrary[animationName](element.children[1]);
        });
        
    }
};